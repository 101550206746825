























import {
  useFindProductCategoryByLocation,
  useLocalFilter,
  useMapProductCategoryLocationToOptions,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ResponseProductCategoryByLocation } from "@/models/interface/product.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectProductCategory extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: String, default: "" })
  locationId!: string;

  options: Array<Option<ResponseProductCategoryByLocation>> = [];
  loading = false;

  created(): void {
    if (this.locationId) {
      this.fetchData(this.locationId);
    }
  }

  @Watch("locationId")
  onChangeLocation(newValue: string): void {
    this.options = [];
    if (!newValue) return;
    this.fetchData(newValue);
  }

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchData(locationId: string): void {
    this.loading = true;
    useFindProductCategoryByLocation(locationId)
      .then(res => {
        this.options = useMapProductCategoryLocationToOptions(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}


















import MNotificationVue from "@/mixins/MNotification.vue";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { useStockAdjustment } from "@/views/logistic/stock-adjustment/hooks";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class FieldAttachment extends Mixins(MNotificationVue) {
  loading = false;

  emits(response: FileCreateResponseDto | null): void {
    this.$emit("onSuccess", response);
  }

  async upload(file: File): Promise<void> {
    const { uploadAttachment } = useStockAdjustment();
    try {
      this.loading = true;
      const res = await uploadAttachment(file);
      this.emits(res);
      this.showNotifSuccess("notif_file_upload_successfully", {
        filename: file.name,
      });
    } catch (error) {
      this.showNotifError("notif_file_upload_failed", { filename: file.name });
    } finally {
      this.loading = false;
    }
  }

  onRemove(): boolean {
    this.$emit("onRemove");
    return true;
  }
}

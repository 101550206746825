var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.title } },
    [
      _c("FormHeader", { ref: "formHeader" }),
      _c(_vm.formTable, { tag: "component" }),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handeBack } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
              ]),
              _vm.$can("create", "stock-adjustment") && _vm.isModeCreate
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading.saveDraft },
                      on: { click: _vm.handleSaveDraft }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save_as_draft")) + " ")]
                  )
                : _vm._e(),
              _vm.$can("update", "stock-adjustment") && _vm.allowUpdate
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading.update, type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.validateForm("update")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                  )
                : _vm._e(),
              _vm.$can("update", "stock-adjustment") &&
              (_vm.allowUpdate || _vm.isModeCreate)
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading.submit, type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.validateForm("submit")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


































































import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import { useProduct } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import {
  ProductDetailDto,
  ProductResponseDto,
} from "@/models/interface/master-product";
import { FormValue, StockAdjRow } from "@/store/stockAdj.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

@Component({
  components: {
    SelectProduct,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) => st.preferenceStore.baseDecimalPlace,
    }),
    ...mapGetters({
      form: "stockAdjStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "stockAdjStore/addStockAdjRow",
      deleteRow: "stockAdjStore/deleteStockAdjRow",
    }),
  },
})
export default class FormTableAdjustment extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  storeBaseDecimalPlace!: number;
  form!: FormValue;
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns = [
    {
      // user pilih dari master product
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      width: "20%",
      scopedSlots: { customRender: "productCode" },
    },
    {
      // user pilih dari master product
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: "20%",
      scopedSlots: { customRender: "productName" },
    },
    {
      // autofill dari product yang dipilih
      //   statis
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      // user input
      title: this.$t("lbl_qty_adjustment"),
      dataIndex: "qty",
      width: "20%",
      scopedSlots: { customRender: "qtyAdjustment" },
    },
    {
      // user input
      title: this.$t("lbl_uom"),
      dataIndex: "uomName",
      width: "20%",
      scopedSlots: { customRender: "uom" },
    },
  ];

  onChangeProduct(
    e: Option<ProductResponseDto> | undefined,
    row: StockAdjRow
  ): void {
    row.productCode = e?.meta?.code || "";
    row.productId = e?.meta?.id || "";
    row.productName = e?.meta?.name || "";
    row.productMerk = e?.meta?.merk || "";
    row.uom = undefined;

    if (!row.productId) return;
    this.getDetailProduct(row.productId, ({ baseUnit, baseUnitId }) => {
      if (baseUnit && baseUnitId) {
        row.uom = {
          key: baseUnitId,
          label: baseUnit,
        };
      }
    });
  }

  getDetailProduct(id: string, cb: (payload: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "formModel",
      attrs: {
        model: _vm.form,
        rules: _vm.formRules,
        "wrapper-col": { span: 12 },
        "label-col": { span: 4 },
        "label-align": "left"
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _vm.form.documentNumber
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_document_number") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "documentNumber", $$v)
                          },
                          expression: "form.documentNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_location"),
                    prop: "warehouseLocation"
                  }
                },
                [
                  _c("SelectRack", {
                    attrs: { "allow-clear": false, "value-as": "id" },
                    model: {
                      value: _vm.form.warehouseLocation,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "warehouseLocation", $$v)
                      },
                      expression: "form.warehouseLocation"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_type"), prop: "type" } },
                [
                  _c(
                    "a-select",
                    {
                      on: { change: _vm.onChangeType },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.key, attrs: { value: item.value } },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                [
                  _c("a-date-picker", {
                    attrs: { format: _vm.DEFAULT_DATE_FORMAT, disabled: "" },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  })
                ],
                1
              ),
              _vm.isOpname
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_category"),
                        prop: "productCategoryId"
                      }
                    },
                    [
                      _c("SelectProductCategory", {
                        attrs: {
                          "location-id": _vm.form.warehouseLocation
                            ? _vm.form.warehouseLocation.key
                            : ""
                        },
                        model: {
                          value: _vm.form.productCategoryId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productCategoryId", $$v)
                          },
                          expression: "form.productCategoryId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOpname
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_brand"), prop: "brand" } },
                    [
                      _c("master-product-merk-select", {
                        model: {
                          value: _vm.form.brand,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "brand", $$v)
                          },
                          expression: "form.brand"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _vm.form.state
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_status"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.state,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "state", $$v)
                          },
                          expression: "form.state"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_notes"),
                    "label-align": "right",
                    prop: "notes"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "extra",
                      fn: function() {
                        return [
                          _c("character-length", {
                            attrs: { value: _vm.form.notes }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.form.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "notes", $$v)
                      },
                      expression: "form.notes"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_attachment"),
                    "label-align": "right",
                    prop: "attachment"
                  }
                },
                [
                  _vm.form.attachment
                    ? [
                        _c(
                          "a-button-group",
                          { attrs: { size: "small" } },
                          [
                            _vm.form.attachment
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "link",
                                      icon: "cloud-download"
                                    },
                                    on: { click: _vm.showAttachment }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lbl_view_attachment")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c("a-button", {
                              attrs: { icon: "delete" },
                              on: { click: _vm.removeAttachment }
                            })
                          ],
                          1
                        )
                      ]
                    : [
                        _c("FieldAttachment", {
                          on: {
                            onSuccess: _vm.onUploadSuccess,
                            onRemove: _vm.removeAttachment
                          }
                        })
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

















































































































import SelectRack from "@/components/custom/select/SelectRack.vue";
import { useDisabledFromTomorrow } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { MAX_LENGTH_CHARACTER } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { LogisticStockAdjustmentType } from "@/models/enums/stock-adjustment.enum";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { FormValue } from "@/store/stockAdj.store";
import { useStockAdjustment } from "@/views/logistic/stock-adjustment/hooks";
import { StockAdjustmentResponseDTO } from "@/views/logistic/stock-adjustment/types";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import FieldAttachment from "./FieldAttachment.vue";
import SelectProductCategory from "./SelectProductCategory.vue";

const ADJUSTMENT_TYPES_OPTIONS: Array<Option> = [
  {
    label: "lbl_stock_adjustment",
    key: 0,
    value: LogisticStockAdjustmentType.STOCK_ADJUSTMENT,
  },
  {
    label: "lbl_stock_opname",
    key: 1,
    value: LogisticStockAdjustmentType.STOCK_OPNAME,
  },
];

@Component({
  components: {
    SelectRack,
    SelectProductCategory,
    FieldAttachment,
  },
  computed: {
    ...mapGetters({
      form: "stockAdjStore/getForm",
      detail: "stockAdjStore/getDetail",
      isOpname: "stockAdjStore/isOpname",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "stockAdjStore/setForm",
    }),
  },
})
export default class FormHeader extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;

  form!: FormValue;
  isOpname!: boolean;
  detail!: StockAdjustmentResponseDTO;
  setForm!: (payload: Partial<FormValue>) => void;

  @Ref("formModel")
  formModel!: FormModel;

  formRules = {
    warehouseLocation: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    type: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    date: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    notes: [
      {
        max: MAX_LENGTH_CHARACTER,
        message: this.$t("lbl_x_character_length_exceed_y", {
          x: this.$t("lbl_note"),
          y: MAX_LENGTH_CHARACTER,
        }),
      },
    ],
  };

  typeOptions: Array<Option> = ADJUSTMENT_TYPES_OPTIONS;

  onUploadSuccess(res: FileCreateResponseDto | null): void {
    this.setForm({
      attachment: res?.url || "",
    });
  }

  onChangeType(e?: LogisticStockAdjustmentType): void {
    this.form.type = e || ("" as LogisticStockAdjustmentType);
    this.clearRows();
  }

  clearRows(): void {
    this.setForm({ stockAdjustmentRows: [] });
  }

  removeAttachment(): void {
    this.setForm({
      attachment: "",
    });
  }

  showAttachment(): void {
    const { viewAttachment } = useStockAdjustment();
    viewAttachment(this.form.attachment);
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload-dragger",
    {
      attrs: {
        accept: ".pdf, .xlsx, image/*",
        multiple: false,
        action: _vm.upload,
        remove: _vm.onRemove
      }
    },
    [
      _c(
        "p",
        { staticClass: "ant-upload-drag-icon" },
        [_c("a-icon", { attrs: { type: "inbox" } })],
        1
      ),
      _c("span", { staticClass: "ant-upload-hint" }, [
        _vm._v(" " + _vm._s(_vm.$t("lbl_upload_drag_and_drop")) + " ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }